.radio-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #f8f6f1;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.radio-container-inner {
  padding: 10px;
  width: 100vw;
  margin-top: 120px;
}

.radio-header-container {
  margin-top: 0;
  width: 100vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.radio-station-item-small {
  width: 50px;
  height: 50px;
  border-radius: 8px;
}

.radio-header-small-container {
  width: 75vw;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.radio-header-small-inner-container {
  flex-direction: row;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  gap: 5px;
}

.radio-playlist-text {
  text-align: left;
  font-size: 14px;
}

.radio-type-text {
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 2rem;
  font-weight: bold;
}

.radio-songs-text {
  text-align: left;
}

.control-bar-title-text {
  font-weight: "bold";
  font-size: 18px;
}

.control-bar-genre-text {
  font-weight: 300;
  font-size: 14px;
  padding-top: 4px;
}

.control-bar-flex {
  display: flex;
  align-items: center;
}

.control-bar-center-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.control-bar-wave-inner {
  width: 100px;
  height: 150px;
}

.control-bar-wave-ref {
  margin-top: 50px;
  height: 0;
  width: 0;
  visibility: hidden;
}

.control-bar-countdown-container {
  background-color: #70322a;
  color: white;
  height: 50px;
  width: 100px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
  transition: 0.5s;
  margin-left: 20px;
}

.control-bar-autoplay {
  background-color: #70322a;
  color: white;
  height: 50px;
  width: 100px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
  transition: 0.5s;
  margin-right: 20px;
  border: none;
}
@media (max-width: 600px) {
  .control-bar-button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .speed-control-container {
    top: 0;
    right: 0;
    margin-right: 15px;
  }
}

@media (max-width: 500px) {
  .control-bar-countdown-container {
    width: 60px;
    margin-left: 0;
    font-size: 8px;
    padding: 0 5px;
    height: 30px;
  }
  .control-bar-autoplay {
    width: 70px;
    margin-right: 0;
    font-size: 8px;
    padding: 0 5px;
    height: 30px;
  }
  .control-bar {
    left: auto;
    right: auto;
    padding: 5px !important;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #efebe1;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.1);
    border-top: 1px solid #e0e0e0;
    z-index: 1000;
    height: 90px;
  }
}

@media (max-width: 840px) {
  .control-bar {
    position: fixed;
    left: 0;
    width: 100%;
    background-color: #efebe1;
    padding: 20px 10px;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.1);
    border-top: 1px solid #e0e0e0;
    z-index: 1000;
    height: 90px;
  }
  .speed-control-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    z-index: 10000;
  }
}
