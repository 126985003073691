.container {
  height: 100vh;
  max-width: 500px;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.header {
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 30px;
}

.login-title {
  color: black;
  font-weight: 600;
  font-size: 50px;
}

.input-group {
  width: 90%;
  margin-bottom: 20px;
}

.label {
  color: #a9a9a9;
  text-align: left;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 16px;
  padding-left: 5px;
}

.input-field {
  width: 95%;
  height: 55px;
  border: none;
  padding-left: 20px;
  border-bottom: 1px solid #a9a9a9;
  border-radius: 12px;
  font-size: 18px;
}

.password-container {
  position: relative;
}

.password-toggle {
  cursor: pointer;
  position: absolute;
  top: 18px;
  right: 20px;
}

.login-button {
  width: 90%;
  margin-bottom: 25px;
  margin-top: 5px;
  background-color: #862623;
  border-radius: 20px;
  height: 50px;
  cursor: pointer;
  align-items: center;
  position: relative;
  display: flex;
  justify-content: center;
}

.button-text {
  color: white;
  font-weight: bold;
}

.indicator {
  position: absolute;
  right: 30px;
}

.signup-text {
  color: black;
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 25px;
}

.signup-link {
  color: black;
  text-decoration: none;
}

.signup-underline {
  text-decoration: underline;
  font-weight: bold;
}

.login-container {
  width: 100%;
  height: 100vh;
  background-color: #f8f6f1;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.no-decoration {
  text-decoration: none;
}
