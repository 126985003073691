/* General Layout Styles */
.course-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
  overflow-x: hidden;
}

.course-pdf-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -10px;
}

.course-pdf-inner-container {
  width: 99%;
  margin-top: 20px;
  margin-bottom: 60px;
}

.course-browse-tracks {
  color: black;
  text-align: left;
  margin-bottom: 4px;
  margin-top: 0;
  font-weight: bold;
  font-size: 20px;
  height: 30px;
  opacity: 0.5;
  transition: height 1s;
}

.course-pdf-fade-in {
  height: 791px;
  transition: 1s;
}

.course-browse-tracks {
  width: 100%;
  height: 40px;
  display: flex;
  color: white;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  background-color: #5a7793;
  border-radius: 8px;
  margin-top: 5px;
  margin-bottom: 30px;
}

.course-track-title {
  margin-bottom: 30px;
}

.course-track-item {
  border-color: black;
  border-style: solid;
  height: 50px;
}

.course-track-list-container {
  flex: 1;
  max-width: 70%;
  display: flex;
  flex-direction: column;
}

.course-browse-container {
  flex-direction: row;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 0;
  padding-top: 50px;
}


.course-browse-inner-container {
  height: 90%;
  width: 30%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.course-browse-inner-container-goldenFiddle {
  height: 100%;
  width: 30%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

.course-browse-tracks-text {
  text-align: left;
  color: black;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 20px;
}

.course-tracks-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  position: relative;
  height: 80%;
}

.course-spacer {
  height: 100px;
}

.course-close-toggle {
  position: absolute;
  right: 20px;
  width: 22px;
  height: 22px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: bold;
}

.course-golden-container {
  color: white;
  font-weight: bold;
  background-color: rgba(239, 175, 70, 1);
  border-radius: 22px;
  margin-bottom: 5px;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 !important;
  border-top: none;
}

.course-golden-image {
  width: 100%;
  margin-top: 18px;
  margin-left: 0px;
  padding-left: 0px;
  border-top: none;
}

.course-golden-current {
  font-size: 18px;
  background-color: white;
  color: #e7b158 !important;
  border-radius: 22px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  cursor: pointer;
  width: 90%;
}

.course-golden-library {
  font-size: 18px;
  background-color: white;
  color: #4d381e;
  border-radius: 22px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 90%;
  margin-top: 5px;

}

.course-golden-zoom {
  font-size: 18px;
  background-color: white;
  color: #e7b158 !important;
  border-radius: 22px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  cursor: pointer;
  width: 90%;
  margin-bottom: 15px;

}

.course-mastery-container {
  color: white;
  font-weight: bold;
  background-color: #8F150A;
  border-radius: 22px;
  margin-bottom: 5px;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 !important;
  border-top: none;
}

.course-mastery-zoom {
  font-size: 18px;
  background-color: white;
  color: #8F150A !important;
  border-radius: 22px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  cursor: pointer;
  width: 90%;
  margin-bottom: 15px;

}

.course-courses-text {
  font-weight: bold;
  text-align: left;
  font-size: 14px;
  margin-bottom: 8px;
}

.course-sidebar-nav {
  cursor: pointer;

  /*margin-bottom: 100px;*/
}

.course-item {
  border-radius: 8px;
  cursor: pointer;
  margin-top: 10px;
}

.course-lesson-container {
  height: 30px;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 5px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.course-lesson-text {
  font-size: 14px;
  z-index: 25;
  cursor: pointer;
  margin-bottom: 0;
  margin-top: 0;
  text-align: left;
  width: 240px;
  height: 30px;
  padding-left: 15px;
}

.video-container-1 {
  align-items: center;
}

.video-container-2 {
  width: 90%;
}

.video-container-3 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0;
  margin-bottom: 20px;
  width: 100%;
}

.course-calendar-button {
  font-size: 18px;
  background-color: #5a779c;
  color: white;
  border-radius: 22px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 80%;
  margin-top: 5px;
  right: 0;
}

.course-office-zoom-button {
  font-size: 18px;
  background-color: #ae8d62;
  color: white;
  border-radius: 22px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 80%;
  margin-top: 5px;
  right: 0;
}

.course-calendar-button-container {
  width: 100%;
  background-color: #f7f7f7;
  position: absolute;
  bottom: 50px !important;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  padding-top: 20px;
  gap: 10px;
}

.course-golden-container {
  width: 300px;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.course-golden-current,
.course-golden-library {
  font-size: 18px;
  background-color: white;
  color: #4d381e;
  border-radius: 22px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 5px;
}

.course-item {
  border-radius: 8px;
  cursor: pointer;
  padding: 10px;
}

.course-lesson-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 5px;
  border-radius: 6px;
  height: 30px;
}

.course-lesson-text {
  font-size: 14px;
  text-align: left;
  padding-left: 15px;
}

.course-track-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 5px;
  border-radius: 8px;
  height: 30px;
}

.course-calendar-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.course-calendar-button {
  background-color: #5a779c;
  color: white;
  border-radius: 22px;
  height: 50px;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
}

.course-side-lessons {
  width: 100%;
  height: 40px;
  display: flex;
  color: white;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  background-color: #5a7793;
  border-radius: 8px;
  margin-top: 5px;
}

.course-complete-side {
  width: 100%;
  height: 40px;
  display: flex;
  color: white;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  background-color: #792d27;
  border-radius: 8px;
}

.course-sidebar-close-container {
  display: flex;
  flex-direction: row;
  height: 10px;
}

.pdf-item-array {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  margin-bottom: 30px;
}

.pdf-item-name {
  border: 2px solid#5a779c;
  border-radius: 8px;
  padding: 10px;
}

.pdf-item-name:hover {
  background-color: #5a779ce3;
  color: white;
  border-radius: 8px;
  padding: 10px;
}

.pdf-item-name-active {
  background-color: #5a779ce3;
  color: white;
  border-radius: 8px;
  padding: 10px;
}

.specialHandoout-layout {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap:10px;
}

.specialHandoout-layout-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  }
.course-video-goldenFiddle {
  width: 100%;
}
.margin-minus {
  margin-top: 650px;
}
.specialHandoout-layout-button {
  width: 550px;
  padding: 8px;
  border: 2px solid #e7b158;
  border-radius: 22px;
}

.specialHandoout-layout-button:hover {
  cursor: pointer;
  padding: 8px;
  background-color:  #e7b158;
  color:white;
  border-radius: 22px;
}

.layout-items {
  margin: 20px 0;
}

.specialHandoout-layout-item {
  width: 60vw;
}

.specialHandoout-margin-top {
  margin-top: 50px;
}

.specialHandooutFile-margin-top {
  margin-top: 10px;
}


.specialHandoout-margin-bottom {
  margin-bottom: 50px;
}

.specialHandoout-margin-bottom-item {
  margin-bottom: 20px;
}
.specialHandoout-margin-top-item {
  margin-top: 20px;
}

.specialHandoout-download-item {
  border: 2px solid #5a779ce3;
  border-radius: 22px;
  cursor: pointer;
  color :#5a779ce3;
  padding: 8px;
  
}

.specialHandoout-download-item:hover {
  border: 2px solid #5a779ce3;
  border-radius: 22px;
  background-color: #e4e4e4;
}

.specialHandoout-download-file {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.specialHandoout-download-file-img {
  width: 10px;
}
.download-item-track {
  border: 2px solid #5a779ce3;
  border-radius: 22px;
  cursor: pointer;
  color :#5a779ce3;
  padding: 8px;
}

.download-item-track:hover {
  border: 2px solid #5a779ce3;
  border-radius: 22px;
  background-color: #e4e4e4;
}
.download-file-track {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;

}

@media (max-width: 500px) {
}

@media (max-width: 768px) { 
  .course-pdf-inner-container{
    width: 99%;
  }

  .specialHandoout-layout-button {
    width: 100%;
  }
  .specialHandoout-layout-item {
    width: 100%;
  }
  .margin-minus {
    margin-top: 600px;
  }
  .specialHandoout-margin-top {
    margin-top: 20px;
  }
  .specialHandoout-margin-bottom {
    margin-bottom: 20px;
  }
  .course-video {
    margin-bottom: 20px;
  }
}